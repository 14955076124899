<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
      <v-dialog
      v-model="unavailable"
      theme="light"
      max-width="700"
      color="white"
      data-testid="globalErrorMessage"
      persistent
    >
      <v-card
        style="background: #fff"
        theme="light"
        min-height="33vh"
      >
        <v-card-title
          color="primary"
          class="justify-center font-weight-bold text-h5"
        >
          Sorry!
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col>
              This product is undergoing maintenance
              and is temporary unavailable.
              Please contact us at <a href="tel:1-833-333-3145">1-833-333-3145</a> for assistance with your application.
            </v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                to="/"
              >
                Return to Home
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card
          elevation="0"
          class="white px-auto"
        >
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2">
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mt-0 pb-16">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0">
          <validation-observer ref="observer">
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <h1 class="title-text text-center mt-2">
                    Trip Info
                  </h1>
                </v-col>
              </v-row>
              <!------------------------Select: Details ----------------------------->
              <v-row class="d-flex justify-center mb-0">
                <v-col
                  cols="12"
                  sm="10"
                  md="9"
                  class="pb-0"
                >
                  <p class="secondary--text text-h4 font-weight-bold">
                    Destination
                  </p>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mt-0">
                <v-col
                  cols="12"
                  sm="10"
                  md="9"
                  class="py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Destination"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="destination"
                      outlined
                      color="primary"
                      :items="countries"
                      :error-messages="errors"
                      label="I'm going to"
                      @click="
                        clickedFieldGlobal(
                          'destination',
                          1,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <!-----------------------Dates ----------------------------------->
              <v-row class="d-flex justify-center my-0">
                <v-col
                  cols="12"
                  sm="10"
                  md="9"
                  class="pb-0"
                >
                  <p class="secondary--text text-h4 font-weight-bold">
                    Dates
                  </p>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center my-0">
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-dialog
                    ref="dialog1"
                    v-model="modal1"
                    :return-value.sync="startDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="startDate"
                          outlined
                          label="From"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            clickedFieldGlobal(
                              'startDate',
                              2,
                              'American Travel Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      scrollable
                      :min="nowDate"
                      :max="maxDate"
                      @change="setEndDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          modal1 = false;
                          clickedFieldGlobal(
                            'cancelStartDate',
                            3,
                            'American Travel Survey v1'
                          );
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog1.save(startDate);
                          clickedFieldGlobal(
                            'okStartDate',
                            4,
                            'American Travel Survey v1'
                          );
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="End Date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="endDate"
                          outlined
                          label="To"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            clickedFieldGlobal(
                              'endDate',
                              5,
                              'American Travel Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      scrollable
                      :min="minEndDate"
                      :max="maxDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          modal2 = false;
                          clickedFieldGlobal(
                            'cancelEndDate',
                            6,
                            'American Travel Survey v1'
                          );
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog2.save(endDate);
                          clickedFieldGlobal(
                            'okEndDate',
                            7,
                            'American Travel Survey v1'
                          );
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Trip Type"
                    rules="required"
                  >
                    <v-select
                      v-model="tripType"
                      outlined
                      :items="tripTypes"
                      :error-messages="errors"
                      label="Trip Type"
                      @click="
                        clickedFieldGlobal(
                          'tripType',
                          8,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <!-------------------------Travellers---------------------------->
              <v-row class="d-flex justify-center my-0">
                <v-col
                  cols="12"
                  sm="10"
                  md="9"
                  class="pb-0"
                >
                  <p class="secondary--text text-h4 font-weight-bold">
                    Travellers
                  </p>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center my-0">
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Travelers"
                    rules="required"
                  >
                    <v-select
                      v-model="numberOfTravelers"
                      outlined
                      :items="travelers"
                      :error-messages="errors"
                      label="Travelers to insure"
                      @click="
                        clickedFieldGlobal(
                          'numberOfTravelers',
                          9,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Living in"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="living"
                      outlined
                      :items="StateList"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      hint="Travelers living in a different state should purchase separate policies."
                      persistent-hint
                      label="Living in"
                      @click="
                        clickedFieldGlobal(
                          'living',
                          10,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-if="numberOfTravelers > 0">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Primary Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Primary Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[0]"
                        v-mask="'##'"
                        outlined
                        label="Primary Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            11,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Primary Traveler Trip Cost"
                      rules="between:1,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[0]"
                        v-mask="'#####'"
                        prefix="$"
                        outlined
                        label="Primary Traveler Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            12,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 1">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Second Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Second Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[1]"
                        v-mask="'##'"
                        outlined
                        label="Second Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            13,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Second Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[1]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            14,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 2">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Third Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Third Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[2]"
                        v-mask="'##'"
                        outlined
                        label="Third Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            15,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Third Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[2]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            16,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 3">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Fourth Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Fourth Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[3]"
                        v-mask="'##'"
                        outlined
                        label="Fourth Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            17,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Fourth Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[3]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            18,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 4">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Fifth Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Fifth Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[4]"
                        v-mask="'##'"
                        outlined
                        label="Fifth Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            19,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Fifth Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[4]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            20,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 5">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Sixth Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Sixth Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[5]"
                        v-mask="'##'"
                        outlined
                        label="Sixth Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            21,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Sixth Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[5]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            22,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 6">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Seventh Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Seventh Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[6]"
                        v-mask="'##'"
                        outlined
                        label="Seventh Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            23,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Seventh Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[6]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            24,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 7">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Eighth Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Eighth Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[7]"
                        v-mask="'##'"
                        outlined
                        label="Eighth Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            25,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Eighth Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[7]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            26,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 8">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Nineth Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nineth Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[8]"
                        v-mask="'##'"
                        outlined
                        label="Nineth Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            27,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nineth Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[8]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            28,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="numberOfTravelers > 9">
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="10"
                    md="9"
                    class="py-0"
                  >
                    <p class="secondary--text text-h4 font-weight-bold">
                      Tenth Traveler
                    </p>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center my-0">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tenth Traveler Age"
                      rules="required|between:1,99"
                    >
                      <v-text-field
                        v-model.number="ages[9]"
                        v-mask="'##'"
                        outlined
                        label="Tenth Traveler Age"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'age',
                            29,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                    class="py-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tenth Traveler Trip Cost"
                      rules="between:0,50000|numeric|required"
                    >
                      <v-text-field
                        v-model.number="tripCosts[9]"
                        prefix="$"
                        outlined
                        label="Individual Trip Cost"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cost',
                            30,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  sm="10"
                  md="9"
                  lg="9"
                >
                  <p class="btm-txt">
                    Travel insurance plans on this website are only available to
                    U.S. residents. Generali Global Assistance is unable to
                    provide coverage for travel to Russia, Belarus, Crimea, and
                    the Donetsk and Luhansk People’s regions, Cuba, Iran, North
                    Korea, Myanmar, Afghanistan and Syria. <br />
                    <br />

                    For any of our U.S. customers traveling in Ukraine, please
                    be advised that Generali's assistance capabilities in this
                    country are substantially limited at this time.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    class="d-flex text-center"
                    :class="mobileDevice ? 'justify-center' : 'justify-end'"
                  >
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="
                        next();
                        clickedFieldGlobal(
                          'getAQuote',
                          31,
                          'American Travel Survey v1'
                        );
                      "
                    >
                      <v-icon left>
                        mdi-airplane
                      </v-icon>
                      Get A Quote
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-overlay v-if="loading">
                <v-row
                  class="mt-12"
                >
                  <v-col class="d-flex justify-center">
                    <ellipse-progress
                      :loading="loading"
                      :size="180"
                      img-src="src/assets/Travel-Icon.png"
                      thickness="5"
                      empty-thickness="3"
                      line-mode="out 5"
                      legend="false"
                      animation="rs 700 1000"
                      font-size="1.5rem"
                      color="#00A1B7"
                      empty-color-fill="transparent"
                      filename="Travel-Icon.png"
                    >
                    </ellipse-progress>
                  </v-col>
                </v-row>
              </v-overlay>
              <v-dialog
                v-model="dateErrorDialog"
                max-width="600"
                persistent
              >
                <v-card>
                  <v-card-title class="red--text font-weight-bold">
                    Date Error
                  </v-card-title><v-card-text class="d-flex justify-center text-center">
                    The trip end date must be after the start date. Please
                    choose a different date.
                  </v-card-text><v-card-actions class="d-flex justify-center">
                    <v-btn
                      class="primary"
                      @click="
                        dateErrorDialog = false;
                        resetDate();
                        clickedFieldGlobal(
                          'closeDateErrorDialog',
                          32,
                          'American Travel Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- <v-dialog
                v-model="errorDialog"
                max-width="900"
              >
                <v-card>
                  <v-card-title class="justify-center font-weight-bold headline red--text">
                    Error
                  </v-card-title>
                  <v-card-title class="justify-center text-center body primmary--text mt-4">
                    <v-row><v-col>{{ errorMessage }}</v-col></v-row>
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <div class="d-flex justify-center">
                        <v-btn
                          color="primary"
                          class="mb-2 mt-4 font-weight-bold"
                          @click="clearError"
                        >
                          Home
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog> -->
              <error-dialog
                :error-dialog="errorDialog"
                :error-message="errorMessage"
              ></error-dialog>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import {
    countries,
    cities,
    getCitiesByCountryCode
  } from 'country-city-location'
  import moment from 'moment'
  import EllipseProgress from '../components/EllipseProgress.vue'
  import idleMixin from '../../../mixins/IdleMixin'
  var states = require('ustates')
  export default {
    components: {
      EllipseProgress
    },
    mixins: [idleMixin],
    data () {
      return {
        unavailable: true,
        nowDate: new Date().toISOString().slice(0, 10),
        errorDialog: false,
        dateErrorDialog: false,
        tripTypes: ['Cruise', 'Air Only', 'Other'],
        tripType: '',
        destination: '',
        primaryDestination: '',
        living: '',
        primaryTravelerAge: '',
        secondTravelerAge: '',
        thirdTravelerAge: '',
        fourthTravelerAge: '',
        fifthTravelerAge: '',
        sixthTravelerAge: '',
        seventhTravelerAge: '',
        eighthTravelerAge: '',
        ninethTravelerAge: '',
        tenthTravelerAge: '',
        tripCosts: [],
        ages: [],
        coverage: '',
        numberOfTravelers: '',
        bdays: '',
        byears: '',
        bmonths: '',
        coverageOptions: ['Small', 'Medium', 'Large'],
        startDate: '',
        endDate: '',
        checkbox: true,
        modal1: false,
        modal2: false,
        places: states,
        menu1: false,
        menu2: false,
        travelers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        destinations: [
          'Destination 1',
          'Destination 2',
          'Destination 3',
          'Destination 4'
        ],
        primaryDestinations: ['p1', 'p2', 'p3', 'p4']
      }
    },
    computed: {
      errorMessage () {
        return this.$store.getters.getErrors
      },
      loading () {
        return this.$store.getters.getloading
      },
      calcage () {
        let age
        if (this.byears && this.bmonths && this.bdays) {
          let date = moment(this.byears + '-' + this.bmonths + '-' + this.bdays)
          age = moment().diff(date, 'years')
        }
        this.ages[0] = age
        return age
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      calcbday () {
        let dateofbirth = '-'
        console.log(this.byears + '-' + this.bmonths + '-' + this.bdays)
        if (this.byears && this.bmonths && this.bdays) {
          dateofbirth = moment(
            this.byears + '-' + this.bmonths + '-' + this.bdays
          ).format('YYYY-MM-DD')
        } else {
          dateofbirth = '-'
        }
        return dateofbirth
      },
      yearsavailable () {
        let maxyear = 2021
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i < maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      maxDate () {
        return moment()
          .add(2, 'y')
          .format('YYYY-MM-DD')
      },
      minEndDate () {
        let minEndDate = ''
        minEndDate = moment(this.startDate)
          .add(1, 'd')
          .format('YYYY-MM-DD')
        console.log('min end date is now ', minEndDate)
        return minEndDate
      },
      countries () {
        let banned = [
          'Russian Federation',
          'Belarus',
          'Cuba',
          'Iran',
          'Iran (Islamic Republic of)',
          'Myanmar',
          'Afghanistan',
          'Syrian Arab Republic',
          'United Kingdom of Great Britain and Northern Ireland'
        ]
        countries.push({ Name: 'United Kingdom' })
        return countries
          .map(entry => entry.Name)
          .filter(item => !banned.includes(item))
      },
      localities () {
        let items = cities.map(entry => entry.name)
        return items
      },
      StateList () {
        console.log(states)
        let statelist = Object.values(states)
        statelist = statelist.slice(0, 51)
        const fileterStates = statelist.filter((item, index) => item.code.toLowerCase() !== 'ar' && item.code.toLowerCase() !== 'mn')
        return fileterStates
      },
      travelDetails () {
        return {
          destination: this.destination,
          primaryDestination: this.primaryDestination,
          living: this.living,
          primaryTraveler: this.primaryTraveler,
          tripcost: this.tripCosts,
          numinsured: this.numberOfTravelers,
          departdate: this.startDate,
          returndate: this.endDate,
          age: this.ages,
          triptype: this.tripType
        }
      }
    },
    mounted () {
      console.log(countries)
      console.log(states)
      this.startDate = moment()
        .add(1, 'd')
        .format('YYYY-MM-DD')
    },
    methods: {
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
      resetDate () {
        this.startDate = moment()
          .add(1, 'd')
          .format('YYYY-MM-DD')
      },
      setEndDate () {
        this.endDate = moment(this.startDate)
          .add(1, 'w')
          .format('YYYY-MM-DD')
      },
      dateError () {
        // if someone picks a start date, check the end date if its set yet
        // if its not set yet then add 2 weeks to the start date and set it
        if (!this.endDate) {
          return
        }
        if (this.startDate && this.endDate) {
          if (moment(this.startDate).isAfter(this.endDate)) {
            this.dateErrorDialog = true
          }
        }
      },
      async next () {
        let complete = await this.$refs.observer.validate()
        if (!complete) {
          console.log('Error validating form')
        } else {
          this.dateError()
          if (this.dateErrorDialog) {
            return
          }
          console.log(this.travelDetails)
          let travelDetails = {
            destination: this.destination,
            primaryDestination: this.primaryDestination,
            living: this.living,
            primaryTraveler: this.primaryTraveler,
            tripcost: this.tripCosts,
            numinsured: this.numberOfTravelers,
            departdate: this.startDate,
            returndate: this.endDate,
            age: this.ages,
            triptype: this.tripType
          }
          console.log('get quote using from vue ', travelDetails)
          // this.$store.dispatch('setTravelCoverageDetails', this.travelDetails)
          this.$store.dispatch('setUSATravelDetails', travelDetails)
          this.$store.dispatch('getTravelQuoteUSA', travelDetails).then(() => {
            if (this.errorMessage) {
              return
            }
            if (!this.errorMessage) {
              this.$store
                .dispatch('getGeneraliCancellationCost', this.travelDetails)
                .then(() => {
                  this.$store.dispatch('setCFARCost')
                })
              this.$router.push({ path: '/travel/selectplanusa' })
            } else {
              this.errorDialog = true
            }
          })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.green-background {
  background-color: #d8fdd7;
}
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.btm-txt {
  text-align: center;
  color: #757575;
}
</style>
<style>
.v-picker--date
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  .v-btn__content {
  color: #000 !important;
}
</style>
